<template>
	<div class="">
		<el-drawer :wrapperClosable="false" :title="$t('i18nn_4de0a112bb772da8')" append-to-body :visible.sync="dialogFile"
			:direction="'rtl'" size="1000px">
			<div class="tableCon" v-loading="loading_base" element-loading-text="加载中...">
				<div class="tableConTable">
					<!-- <div class="tableConTop">
					<el-row>
						<el-col :span="5" class="tableConTopLeft">
							<h3>
								<el-button type="primary" round icon="el-icon-back" size="mini"
									@click="goBack()">{{$t('i18nn_ca5a01a5adf20fe7')}}</el-button>
								<span class="tct_tit">{{$t('i18nn_4de0a112bb772da8')}}</span>
							</h3>
						</el-col>
						<el-col :span="19" class="tableConTopRig">
							<el-button type="success" circle icon="el-icon-refresh" size="small" @click="initData()">
							</el-button>
						</el-col>
					</el-row>
				</div> -->


					<el-tabs v-model="detTabActiveName" type="border-card">
						<el-tab-pane label="" name="first" v-loading="loading">
							<span slot="label"><i class="el-icon-document"></i> <span>{{$t('i18nn_e6a250894a04db4c')}}</span></span>

							<el-card shadow="never" style="margin-bottom: 5px;">
								<!-- <div style="display: flex;justify-content: space-between;"> -->
								<!-- <div style="flex:1"> -->
								<el-descriptions title="" :column="4">

									<el-descriptions-item
										:label="$t('i18nn_559fed47b0e95407')">{{baseData.inWhCode}}</el-descriptions-item>
									<el-descriptions-item :label="$t('i18nn_8af1fd820046d3c1')">{{baseData.whNo}}</el-descriptions-item>


									<el-descriptions-item
										:label="$t('i18nn_0ae081c3b8c4d4a1')">{{baseData.commitDate}}</el-descriptions-item>

									<el-descriptions-item
										:label="$t('i18nn_9be246e60da2a0a2')">{{baseData.arriveWhDate}}</el-descriptions-item>
									<el-descriptions-item>
										<template slot="label">
											<span><span>{{$t('i18nn_3d3bc466f13154d4')}}</span>/<span>{{$t('i18nn_daa60c502de1462f')}}</span></span>
										</template>
										{{baseData.relationNo}}</el-descriptions-item>
									<el-descriptions-item
										:label="$t('i18nn_1db6434a8c2ba647')">{{baseData.arriveWhWayName}}</el-descriptions-item>

									<el-descriptions-item
										:label="$t('i18nn_6e33a5e062b119ff')">{{baseData.isShowSkuName}}</el-descriptions-item>
									<el-descriptions-item
										:label="$t('i18nn_e137950d1897817e')">{{baseData.isLiveUploadName}}</el-descriptions-item>
									<el-descriptions-item
										:label="$t('i18nn_e1e2b21c7b4b951f')">{{baseData.trOpTypeName}}</el-descriptions-item>
								</el-descriptions>

								<!-- <el-descriptions title="" :column="4" v-if="'10'==baseData.trOpType">
					<el-descriptions-item>
						<template slot="label">
							<span><span>{{$t('i18nn_e1d76a34da86da92')}}</span>FBA</span>
						</template>
					{{baseData.isFbaName}}</el-descriptions-item>
					<el-descriptions-item>
					<template slot="label">
						<span>FBA<span>{{$t('i18nn_5acbec83efb27445')}}</span></span>
					</template>
					{{baseData.fbaAddrCode}}</el-descriptions-item>
					<el-descriptions-item :label="$t('i18nn_8758fd50c87d6c9c')">
						{{ baseData.addr }},{{ baseData.city }},{{ baseData.state }},{{ baseData.country }}
					</el-descriptions-item>
					<el-descriptions-item :label="$t('i18nn_5a9fb0915ecea987')">{{baseData.phone}}</el-descriptions-item>
					<el-descriptions-item :label="$t('i18nn_c4913ab43009b365')">{{baseData.postalCode}}</el-descriptions-item>
				</el-descriptions> -->

								<el-descriptions title="">
									<el-descriptions-item :label="$t('i18nn_f5d43732e3f6cf4d')">
										<ul>
											<li v-for="(item,index) in baseData.fileList" :key="index">
												<el-image style="width: 50px; height: 50px;vertical-align: middle;" :z-index="9999"
													:fit="'contain'" :src="item.url"
													:preview-src-list="baseData.fileList.map(itemPre=> { return itemPre.url})">
													<div slot="error" class="image-slot">
														<i class="el-icon-document"></i>
													</div>
												</el-image>
												<a :href="item.url" :title="item.url" target="_blank">{{ item.fileName }}</a>
											</li>
										</ul>
									</el-descriptions-item>
								</el-descriptions>

								<el-descriptions title="">
									<el-descriptions-item :label="$t('i18nn_15b3627faddccb1d')">
										<span class="pre-text" style="color:#e6a23c;">
											{{baseData.remark}}
										</span>
									</el-descriptions-item>
								</el-descriptions>

							</el-card>


							<el-card shadow="never" v-loading="loading_load" style="margin-bottom: 5px;">
								<div slot="header" class="">
									<div style="display: flex;justify-content: space-between;">
										<div>
											<strong>{{$t('i18nn_3dc377fecc5acdb1')}}</strong>
										</div>
									</div>
								</div>


								<el-table id="out-table2" ref="multipleTable" :data="baseData.recoreList" stripe :border="true"
									:max-height="$store.state.tableMaxHeight5" style="width: 100%" size="small">
									<!-- <el-table-column v-if="isSel" type="index" :index="$Utils.tableIndex(pagination)" fixed="left" width="50" align="center" :label="$t('7b1c2b1adc920d9c')"></el-table-column> -->
									<el-table-column type="index" fixed="left" width="50" align="center"
										:label="$t('7b1c2b1adc920d9c')"></el-table-column>

									<el-table-column prop="statusName" :label="$t('Storage.packing_list.status')">
										<template slot-scope="scope">
											<el-tag type=""
												v-if="'0' == scope.row.status">{{ $Utils.i18nKeyText(scope.row,'statusName') }}</el-tag>
											<el-tag type="success"
												v-else-if="'10' == scope.row.status">{{ $Utils.i18nKeyText(scope.row,'statusName') }}</el-tag>
											<el-tag type="warning"
												v-else-if="'20' == scope.row.status">{{ $Utils.i18nKeyText(scope.row,'statusName') }}</el-tag>
											<el-tag type="danger"
												v-else-if="'28' == scope.row.status">{{ $Utils.i18nKeyText(scope.row,'statusName') }}</el-tag>
											<el-tag type="warning"
												v-else-if="'30' == scope.row.status">{{ $Utils.i18nKeyText(scope.row,'statusName') }}</el-tag>
											<el-tag type="info"
												v-else-if="'36' == scope.row.status">{{ $Utils.i18nKeyText(scope.row,'statusName') }}</el-tag>
											<el-tag type="info" v-else>{{ $Utils.i18nKeyText(scope.row,'statusName') }}</el-tag>
										</template>
									</el-table-column>

									<el-table-column prop="verifyStatusName" :label="$t('i18nn_850ab41707d9ec89')">
										<template slot-scope="scope">
											<el-tag type=""
												v-if="'0' == scope.row.verifyStatus">{{ $Utils.i18nKeyText(scope.row,'verifyStatusName') }}
											</el-tag>
											<el-tag type="success" v-else-if="'1' == scope.row.verifyStatus">
												{{ $Utils.i18nKeyText(scope.row,'verifyStatusName') }}
											</el-tag>
											<el-tag type="info" v-else>{{ $Utils.i18nKeyText(scope.row,'verifyStatusName') }}</el-tag>
										</template>
									</el-table-column>

									<!-- <el-table-column prop="inWhTypeName" :label="$t('i18nn_643e08ba53f72f39')" width="">
								</el-table-column> -->

									<el-table-column prop="ctnMark" :label="$t('i18nn_73fc38e67381ae73')" width="">
									</el-table-column>

									<el-table-column prop="ctnCount" :label="$t('i18nn_bdc361ba04506136')" width="">
									</el-table-column>

									<el-table-column prop="stock" :label="$t('i18nn_65ff76c08629a2c8')">
										<template slot-scope="scope">
											<div v-if="!!scope.row.stock">
												<el-tag size="small" type="success" v-if="scope.row.ctnCount==scope.row.stock">
													{{scope.row.stock}}
												</el-tag>
												<el-tag size="small" type="danger" v-else>
													{{scope.row.stock}}
												</el-tag>
												<!-- <strong>
												{{scope.row.stock}}
											</strong> -->
											</div>
										</template>
									</el-table-column>

									<el-table-column prop="inWhPallte" :label="$t('i18nn_83125000f5142ed1')" width="">
									</el-table-column>

									<el-table-column prop="ctnUnitName" :label="$t('i18nn_b6bf0a07fe26f74f')" width="">
									</el-table-column>

									<el-table-column prop="ctnL">
										<template slot="header">
											<div><span>{{$t('i18nn_53f9f6f694a5b0a8')}}</span>(L*W*H)</div>
										</template>
										<template slot-scope="scope">
											<div>
												{{scope.row.ctnL}}*{{scope.row.ctnW}}*{{scope.row.ctnH}}
											</div>
										</template>
									</el-table-column>

									<el-table-column prop="ctnWeight" :label="$t('i18nn_9e168db87b716866')" width="">
									</el-table-column>

									<el-table-column prop="goodsSku" width="" v-if="'1'==baseData.isShowSku">
										<template slot="header">
											<div><span>{{$t('i18nn_c5ef0b068e92e23d')}}</span>SKU</div>
										</template>
									</el-table-column>

									<el-table-column prop="ctnSkuCount" :label="$t('i18nn_85a10d3e5ea0e103')" width=""
										v-if="'1'==baseData.isShowSku">
									</el-table-column>

									<el-table-column prop="skuTotal" :label="$t('i18nn_bad53577db0da2d3')" width=""
										v-if="'1'==baseData.isShowSku">
									</el-table-column>
									<!-- <el-table-column prop="remark" :label="$t('i18nn_15b3627faddccb1d')" min-width="150" show-overflow-tooltip>
					</el-table-column> -->
									<el-table-column :label="$t('Storage.tableColumn.operation')" width="160px">
										<template slot-scope="scope">
											<div>
												<el-button @click="showDetList($event, scope.row)" type="primary" size="mini"
													icon="el-icon-view">{{$t('i18nn_202e232a73f0abe1')}}</el-button>
											</div>

										</template>
									</el-table-column>
								</el-table>
							</el-card>

							<el-card shadow="never">
								<div slot="header" class="">
									<strong>{{$t('i18nn_74c2b69060be9bfe')}}</strong>
								</div>
								<div>
									<el-table ref="multipleTable2" :data="tableData2" stripe style="width: 100%" size="small"
										v-loading="loadingUpload">
										<el-table-column type="index" fixed="left" width="50" align="center"
											:label="$t('Storage.tableColumn.no')"></el-table-column>
										<el-table-column prop="fileName" :label="$t('i18nn_ea4756bc1642e0f1')"></el-table-column>
										<el-table-column :label="$t('i18nn_2674282277c3714e')">
											<template slot-scope="scope">
												<el-image style="width: 100px; height: 100px" :z-index="9999" :fit="'contain'"
													:src="scope.row.url" :preview-src-list="tableData2.map(itemPre=> { return itemPre.url})">
													<div slot="error" class="image-slot">
														<i class="el-icon-document"></i>
													</div>
												</el-image>
											</template>
										</el-table-column>
										<el-table-column prop="typeName" :label="$t('i18nn_184333c81babf2f1')"></el-table-column>
										<el-table-column prop="url" :label="$t('i18nn_d54012286fece209')" show-overflow-tooltip>
											<template slot-scope="scope">
												<a :href="scope.row.url" target="_blank">{{ scope.row.url }}</a>
											</template>
										</el-table-column>
									</el-table>
								</div>
							</el-card>
						</el-tab-pane>
						<el-tab-pane label="" name="second">
							<span slot="label"><i class="el-icon-coin"></i> <span>{{$t('i18nn_d7315fb8114eb446')}}</span></span>

							<FeeModelById :id="id"></FeeModelById>
						</el-tab-pane>
					</el-tabs>
				</div>
			</div>
		</el-drawer>
		<!-- 出库明细 -->
		<TransportInventoriesOutWhDet :openTime="TraInvOutDetOpenTime" :inWhRecord="TraInvOutDetInWhRecord"
			:row="TraInvOutDetRow"></TransportInventoriesOutWhDet>

	</div>
</template>
<script>
	// import { deepClone } from '@/utils/utils.js';
	// import WhTransportConfirmReceiptCom from '@/components/WarehouseCenter2/Transport/WhTransportConfirmReceiptCom.vue';
	import TransportInventoriesOutWhDet from '@/components/StorageCenter/Transport/TransportInventoriesOutWhDet.vue';
	import FeeModelById from '@/components/StorageCenter/Other/FeeModelById.vue';
	export default {
		components: {
			// WhTransportConfirmReceiptCom
			TransportInventoriesOutWhDet,
			FeeModelById
		},
		props: {
			openTime: {},
			id: {}
		},
		data() {
			return {
				dialogFile: false,
				detTabActiveName: 'first',

				// id: "",

				TraInvOutDetOpenTime: "",
				TraInvOutDetRow: {},
				TraInvOutDetInWhRecord: "",

				//选择
				currentSel: {},
				//表格数据
				//loading,表格数据
				loading: false,
				loading_load: false,
				//表格数据
				// tableData: [],
				//缓存，原数据
				// tableDataCatch: [],
				baseData: {},
				loading_base: false,

				loadingUpload: false,
				tableData2: [],


				//分页数据
				pagination: this.$Utils.defaultPagination(),
				//表格选择的数据
				multipleSelection: [],
				//查询，排序方式
				//"orderBy": "auditTime,fCon,goodsCon", //排序字段
				selectOption: {
					// wh_size_unit:[],
					wh_size_unit: [],
					wh_transfer_Inwh_type: [],
					wh_transfer_arrive_way: []
				},
				// showType: "", //展示类型
				filterData: {
					orderBy: 'create_time', //排序字段
					sortAsc: 'desc', //desc降序，asc升序

					keyword: '',
				},
				// ReceiptData: {
				// 	userId: "",
				// 	status: ""
				// },
			};
		},
		watch: {
			openTime: function(newVal, oldVal) {
				console.log('openTime');
				this.dialogFile = true;

				this.initData();
			}
		},
		activated() {

		},
		//创建时
		created() {

		},
		//编译挂载前
		mounted() {
			// this.getDicData();
		},
		methods: {
			initData() {
				//跳转到第一页
				this.pagination.current_page = 1;
				this.currentSel = {};
				this.multipleSelection = [];

				this.baseData = {};
				if (this.id) {
					this.getBasePageData();
				}
			},
			// goBack() {
			// 	// console.log('window.history.length',window.history.length);
			// 	// if (window.history.length > 2) {
			// 	// 	this.$router.go(-1);
			// 	// } else {
			// 	this.$router.push({
			// 		name: 'TransportInWh'
			// 	});
			// 	// }
			// },

			//选择行
			handleCurrentChange(row, event, column) {
				// this.$refs.multipleTable.toggleRowSelection(row, true); //点击选中
			},
			//多选
			handleSelectionChange(val) {
				console.log(val);
				// this.multipleSelection = val;
			},
			showDetList($event, row) {
				$event.stopPropagation();
				this.TraInvOutDetOpenTime = new Date().getTime();
				this.TraInvOutDetRow = row;
				this.TraInvOutDetInWhRecord = row.id;
			},

			//请求分页数据
			getBasePageData() {
				// let _this = this;
				this.loading_base = true;
				this.$http
					.get(this.$urlConfig.WhTransferEditQuery + "/" + this.id, {})
					.then(({
						data
					}) => {

						this.loading_base = false;
						if (200 == data.code && data.data) {
							//表格显示数据
							this.baseData = data.data;
							this.getListData(this.baseData.id);
						} else {
							this.$message.warning(data.msg ? data.msg : this.$t('tips.queryErrorFailed'));
						}
					})
					.catch(error => {
						console.log(error);
						this.$message.error(this.$t('tips.queryRequestFailed'));
						this.loading_base = false;
					});
			},
			//收货清单
			getListData(relationId) {
				this.loadingUpload = true;
				this.$http
					.get(this.$urlConfig.WhFileUploadList + '/' + relationId + '/60', {})
					.then(({
						data
					}) => {
						console.log(this.$t('i18nn_4195755cd53e871a'));
						console.log(data);
						this.loadingUpload = false;
						if (200 == data.code) {
							// if (data.rows) {
							this.tableData2 = data.rows;
						} else {
							// this.$alert('查询失败,请重试', this.$t('tips.tipsTitle'), {
							// 	type: 'warning'
							// 	//confirmButtonText: this.$t('i18nn_204ffab8a6e01870'),
							// });
						}
					})
					.catch(error => {
						console.log(error);
						// console.log(this.$t('tips.submitError'));
						this.loadingUpload = false;
						// this.$alert('查询失败,请重试！', this.$t('tips.tipsTitle'), {
						// 	type: 'warning'
						// 	//confirmButtonText: this.$t('i18nn_204ffab8a6e01870'),
						// });
					});
			},

		}
	};
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">

</style>