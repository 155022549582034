<template>
	<div class="mainTem">
		<!-- <div class="buyerHeader"><h3 class="buyerHeaderTit">{{$t('i18nn_6b1945319eb18d5f')}}</h3></div> -->
		<!-- <div style="text-align: center; margin-top: 10px;">
      <el-button-group>
        <el-button type="primary"  size="medium" style="width: 200px;">{{$t('i18nn_5b14580d813b4a45')}}</el-button>
        <el-button type="primary" plain size="medium" style="width: 200px;" @click="toPageUrl('PartnerBillSettlementDet')">{{$t('i18nn_1b613e926a1e65dd')}}</el-button>
      </el-button-group>
    </div> -->
		<div class="tableConTop">
			<el-row>
				<el-col :span="5" class="tableConTopLeft">
					<h3>
						<span class="tct_tit">{{$t('i18nn_56b48b3b6de43bde')}}</span>
						<!-- <el-tooltip effect="dark" :content="$t('btn.long_Export_excel')"ng_Export_excel')" placement="top">
							<el-button type="warning" circle icon="el-icon-download" size="mini"
								@click="exportExcelAction()"></el-button>
						</el-tooltip> -->
					</h3>
				</el-col>
				<el-col :span="19" class="tableConTopRig">
					<!-- <ul class="actionConList"> -->
					<!-- <li> -->
					<el-button type="success" @click="toCreate()" size="small"
						icon="el-icon-plus">{{$t('i18nn_786593149c0bf9f8')}}</el-button>
					<!-- </li> -->
					<!-- <li v-if="10==filterData.status"> -->
					<el-button type="danger" @click="commitCancelAction($event, null)" size="small" icon="el-icon-sold-out"
						v-if="10==filterData.status">{{$t('i18nn_b4e5993f97fe04ee')}}</el-button>

					<!-- </li> -->
					<!-- </ul> -->

					<el-button type="success" circle icon="el-icon-refresh" size="small" @click="initData()">
					</el-button>
				</el-col>
			</el-row>
		</div>

		<div class="filterCon" style="">
			<div class="filterBarList">
				<el-menu :default-active="filterData.status" mode="horizontal" @select="handleSelectStatus">
					<el-menu-item :index="item.value" v-for="item in selectOption.statusList" :key="item.value">
						<span>
							{{i18nFormatter(item.value)}}
						</span>
					</el-menu-item>
				</el-menu>
			</div>
			<div class="filterAction">
				<!-- <ul class="actionConList">
					<li>
						<el-button type="success" @click="toCreate()" size="small" icon="el-icon-plus">{{$t('i18nn_786593149c0bf9f8')}}</el-button>
					</li>
					<li v-if="10==filterData.status">
						<el-button type="danger" @click="commitCancelAction($event, null)" size="small" icon="el-icon-sold-out">{{$t('i18nn_b4e5993f97fe04ee')}}</el-button>
						
					</li>
				</ul> -->
				<ul class="filterConList">
					<li>
						<span class="">{{ $t('i18nn_f77f473d27f48bdc') }}</span>
						<SelAgentUser ref="SelAgentUser" @change="changeAgentUser" size="small"></SelAgentUser>
					</li>
					<li>
						<span class="">{{$t('i18nn_c944a6686d996ab3')}}</span>
						<whNoSelect @changeData="changWhNo" size="small"></whNoSelect>
					</li>
					<li>
						<span class="filterName">{{$t('i18nn_43a3586339251494')}}</span>
						<el-date-picker size="small" v-model="filterData.commitDateArr" type="daterange" align="right" unlink-panels
							range-separator="-" :start-placeholder="$t('i18nn_7d2ffc27a09ff792')"
							:end-placeholder="$t('i18nn_46f7be1133b3e2c6')" value-format="yyyy-MM-dd" format="yyyy-MM-dd"
							@change="initData()" :picker-options="pickerOptions">
						</el-date-picker>
					</li>
					<li>
						<span>{{ $t('i18nn_f353b1ebbdc25be4') }}</span>
						<el-input :placeholder="$t('i18nn_3ee4c9b76289e93a')" size="small" v-model="filterData.keyword"
							maxlength="50" :clearable="true" @keyup.enter.native="initData()" style="width: 250px;">
						</el-input>
					</li>



					<li>
						<el-button icon="el-icon-search" size="small" type="primary" @click="initData()"></el-button>
					</li>
					<!-- <li>
						<el-button icon="el-icon-s-operation" size="small" type="primary" @click="openMoreFilter()">
						</el-button>
					</li> -->
				</ul>
			</div>
		</div>
		<!--更多筛选-->
		<!-- <el-drawer :wrapperClosable="false" custom-class="drawerFilter" :title="$t('i18nn_c4ca802cf2230b0b')" :visible.sync="drawerFilter" :direction="'rtl'">
			<div class="drawerFilterCon">
				<ul class="drawerFilterList">
					<li>
						<span class="filterName">{{ $t('i18nn_f77f473d27f48bdc') }}</span>
						<SelAgentUser ref="SelAgentUser" @change="changeAgentUser" size="medium"></SelAgentUser>
					</li>
					<li>
						<span class="filterName">{{$t('i18nn_c944a6686d996ab3')}}</span>
						<whNoSelect @changeData="changWhNo" size="medium"></whNoSelect>
					</li>
					<li>
						<span class="filterName">{{$t('i18nn_43a3586339251494')}}</span>
						<el-date-picker v-model="filterData.commitDateArr" type="daterange" align="right" unlink-panels
							range-separator="-" :start-placeholder="$t('i18nn_7d2ffc27a09ff792')"
							:end-placeholder="$t('i18nn_46f7be1133b3e2c6')" value-format="yyyy-MM-dd"
							format="yyyy-MM-dd" size="medium" @change="initData()" :picker-options="pickerOptions">
						</el-date-picker>
					</li>
					<li>
						<span class="filterName">{{$t('i18nn_66f0e38b9285894b')}}</span>
						<el-radio-group v-model="filterData.wh_car_tms_type" size="medium" @change="initData()">
							<el-radio-button border :label="''">{{$t('i18nn_16853bda54120bf1')}}</el-radio-button>
							<el-radio-button border v-for="item in selectOption.wh_car_tms_type" :key="item.code"
								:label="item.code">{{ $Utils.i18nCodeText(item) }}</el-radio-button>
						</el-radio-group>
					</li>
					<li class="filterBtnCon">
						<el-button icon="el-icon-search" size="medium" type="primary" @click="queryMoreFilter()">{{$t('i18nn_1e7246dd6ccc5539')}}</el-button>
					</li>
				</ul>
			</div>
		</el-drawer> -->

		<div class="" v-loading="loading_load" :element-loading-text="$t('tips.loading')">
			<el-table ref="multipleTable" :data="tableData" stripe :border="true" style="width: 100%" size="small"
				:max-height="$store.state.frameConHeightWh1" @selection-change="handleSelectionChange"
				@row-click="handleCurrentChange">

				<el-table-column type="selection" fixed="left" align="center" width="55">
				</el-table-column>

				<el-table-column type="index" :index="$Utils.tableIndex(pagination)" fixed="left" width="50" align="center"
					:label="$t('Storage.tableColumn.no')"></el-table-column>

				<el-table-column prop="inWhCode" :label="$t('i18nn_35ec768075eb8f4e')" width="150">
					<template slot-scope="scope">
						<div>
							<!-- <el-link v-if="scope.row.inWhCode" type="primary" size="mini" icon="" @click="showDetList($event, scope.row)">
							{{ scope.row.inWhCode }}
						</el-link> -->

							<div class="link-copy" v-if="scope.row.inWhCode">
								<span class="link-text" @click="showDetList($event, scope.row, scope.$index)">
									{{ scope.row.inWhCode }}
								</span>
								<el-tooltip class="link-copy-icon" effect="dark" :content="$t('i18nn_29f67f0644a78bf3')"
									placement="top">
									<i class="el-icon-document-copy" @click="$Utils.copyText(scope.row.inWhCode)"></i>
								</el-tooltip>
							</div>

						</div>
					</template>
				</el-table-column>

				<el-table-column prop="whNo" :label="$t('i18nn_8af1fd820046d3c1')"></el-table-column>
				<!-- <el-table-column prop="inWhTypeName" :label="$t('i18nn_643e08ba53f72f39')"></el-table-column> -->
				<el-table-column prop="arriveWhWayName" :label="$t('i18nn_1db6434a8c2ba647')">
					<!-- <template slot-scope="scope">
						{{ $Utils.i18nKeyText(scope.row,'arriveWhWayName') }}
					</template> -->
					<template slot-scope="scope">
						<div class="recDet">
							<div class="recItem">
								{{scope.row.arriveWhWayName}}
							</div>
							<div class="recItem">
								<el-tag size="mini" effect="plain" v-if="'1'==scope.row.trOpType">
									{{scope.row.trOpTypeName}}
								</el-tag>
								<el-tag size="mini" effect="plain" type="success" v-else>
									{{scope.row.trOpTypeName}}
								</el-tag>
							</div>
						</div>
					</template>
				</el-table-column>
				<!-- <el-table-column prop="trOpTypeName" :label="$t('i18nn_e1e2b21c7b4b951f')">
					<template slot-scope="scope">
						{{ $Utils.i18nKeyText(scope.row,'trOpTypeName') }}
					</template>
				</el-table-column> -->

				<!-- <el-table-column prop="arriveWhDate" :label="$t('i18nn_9be246e60da2a0a2')"></el-table-column> -->
				<!-- <el-table-column prop="inWhPallte" :label="$t('i18nn_33a85575edeaacaa')"></el-table-column> -->
				<el-table-column prop="relationNo" :label="$t('i18nn_3d3bc466f13154d4')+'/'+$t('i18nn_daa60c502de1462f')"
					min-width="120">
					<!-- <template slot="header">
						<div><span>{{$t('i18nn_3d3bc466f13154d4')}}</span>/<span>{{$t('i18nn_daa60c502de1462f')}}</span></div>
					</template> -->
				</el-table-column>
				<el-table-column prop="" :label="'打托数'" min-width="110">
					<template slot-scope="scope">
						<div class="recDet">
							<!-- <div class="recItem">
								{{scope.row.relationNo}}
							</div> -->
							<div class="recItem">
								<span>{{$t('i18nn_197e2039cf365afa')}}</span>:<span
									style="padding-left: 5px;">{{scope.row.inWhPallte}}</span>
							</div>
							<div class="recItem" v-if="scope.row.isShowSku">
								<el-tag size="mini" v-if="'1'==scope.row.isShowSku">
									展示箱内SKU
								</el-tag>
								<el-tag size="mini" type="info" v-else-if="'0'==scope.row.isShowSku">
									不展示箱内SKU
								</el-tag>
							</div>
							<div class="recItem" v-if="scope.row.isLiveUpload">
								<el-tag size="mini" v-if="'1'==scope.row.isLiveUpload">
									是现场卸货
								</el-tag>
								<el-tag size="mini" type="info" v-else-if="'0'==scope.row.isLiveUpload">
									非现场卸货
								</el-tag>
							</div>
						</div>
					</template>
				</el-table-column>
				<!-- <el-table-column prop="isShowSkuName" :label="$t('i18nn_696eca4149742942')">
						<template slot-scope="scope">
							<div>
								<el-tag type="success" v-if="1==scope.row.isShowSku">{{ $Utils.i18nKeyText(scope.row,'isShowSkuName') }}</el-tag>
								<el-tag type="info" v-else-if="0==scope.row.isShowSku">{{ $Utils.i18nKeyText(scope.row,'isShowSkuName') }}</el-tag>
								<div v-else>
									{{ $Utils.i18nKeyText(scope.row,'isShowSkuName') }}
								</div>
							</div>
						</template>
				</el-table-column> -->

				<!-- <el-table-column prop="addedService" :label="$t('i18nn_46b944e39511234b')"></el-table-column> -->

				<!-- <el-table-column prop="commitDate" :label="$t('i18nn_0ae081c3b8c4d4a1')"> -->
				<!-- </el-table-column> -->

				<!-- <el-table-column prop="inWhDate" :label="$t('i18nn_4647ba3fc60f0151')"></el-table-column> -->

				<!-- <el-table-column prop="isLiveUploadName" :label="$t('i18nn_e137950d1897817e')">
					<template slot-scope="scope">
						<div>
							<el-tag type="success" v-if="1==scope.row.isLiveUpload">{{ $Utils.i18nKeyText(scope.row,'isLiveUploadName') }}</el-tag>
							<el-tag type="info" v-else-if="0==scope.row.isLiveUpload">{{ $Utils.i18nKeyText(scope.row,'isLiveUploadName') }}</el-tag>
							<div v-else>
								{{ $Utils.i18nKeyText(scope.row,'isLiveUploadName') }}
							</div>
						</div>
					</template>
				</el-table-column> -->

				<!-- <el-table-column prop="inWhPallte" :label="$t('i18nn_197e2039cf365afa')"></el-table-column> -->

				<el-table-column prop="" :label="$t('i18nn_43a3586339251494')" min-width="220">
					<template slot-scope="scope">
						<div>
							<ul>
								<li>
									<span>{{$t('i18nn_0ae081c3b8c4d4a1')}}</span>:{{scope.row.commitDate}}
								</li>
								<li>
									<span>{{$t('i18nn_9e9277ad9980fd7d')}}</span>:{{scope.row.arriveWhDate}}
								</li>
								<li>
									<span>{{$t('i18nn_4647ba3fc60f0151')}}</span>:{{scope.row.inWhDate}}
								</li>
							</ul>
						</div>
					</template>
				</el-table-column>

				<el-table-column prop="fileList" :label="$t('Storage.packing_list.attachment')">
					<template slot-scope="scope">
						<div @click="openUploadFile($event, scope.row)">
							<div v-if="scope.row.fileList">
								<el-link type="primary">
									...<span>{{$t('i18nn_73bbf51487ec69e9')}}</span><span>{{scope.row.fileList.length}}</span><span>{{$t('i18nn_7f18cb0ceb569eb0')}}</span>
								</el-link>
							</div>
						</div>
					</template>
				</el-table-column>

				<el-table-column prop="remark" :label="$t('Storage.tableColumn.remark')">
					<template slot-scope="scope">
						<el-popover placement="top" trigger="hover">
							<div class="pre-text">{{ scope.row.remark }}</div>
							<span class="over_ellipsis" slot="reference" style="color:red;">{{scope.row.remark}}</span>
						</el-popover>
					</template>
				</el-table-column>

				<!-- <el-table-column prop="commitDate" :label="$t('i18nn_0ae081c3b8c4d4a1')"></el-table-column> -->

				<el-table-column prop="statusName" :label="$t('Storage.packing_list.status')">
					<template slot-scope="scope">
						<el-tag type="" v-if="'0' == scope.row.status">{{ $Utils.i18nKeyText(scope.row,'statusName') }}</el-tag>
						<el-tag type="success"
							v-else-if="'10' == scope.row.status">{{ $Utils.i18nKeyText(scope.row,'statusName') }}</el-tag>
						<el-tag type="warning"
							v-else-if="'20' == scope.row.status">{{ $Utils.i18nKeyText(scope.row,'statusName') }}</el-tag>
						<el-tag type="danger" effect="plain"
							v-else-if="'28' == scope.row.status">{{ $Utils.i18nKeyText(scope.row,'statusName') }}</el-tag>
						<el-tag type=""
							v-else-if="'30' == scope.row.status">{{ $Utils.i18nKeyText(scope.row,'statusName') }}</el-tag>
						<el-tag type="danger"
							v-else-if="'35' == scope.row.status">{{ $Utils.i18nKeyText(scope.row,'statusName') }}</el-tag>
						<el-tag type="info" v-else>{{ $Utils.i18nKeyText(scope.row,'statusName') }}</el-tag>
					</template>
				</el-table-column>

				<!-- <el-table-column prop="remark" :label="$t('Storage.tableColumn.remark')"></el-table-column> -->

				<el-table-column :label="$t('Storage.tableColumn.operation')" width="280px" fixed="right" align="left">
					<template slot-scope="scope">
						<div>


							<div style="margin-bottom: 10px;">
								<!-- <el-button @click="showDetList($event, scope.row)" type="primary" size="mini"
									icon="el-icon-view">{{$t('FormMsg.details_View')}}</el-button> -->
								<el-button @click="openUploadFile($event, scope.row)" type="success" size="mini"
									icon="el-icon-upload2">{{$t('FormMsg.upload_attachment')}}</el-button>

								<el-button @click="openQARecList($event, scope.row)" type="primary" plain size="mini"
									icon="el-icon-service">{{$t('i18nn_10bc8a2b45aa5636')}}</el-button>
							</div>

							<div style="margin-bottom: 10px;" v-if="'0' == scope.row.status">
								<el-button @click="commitAction($event, scope.row)" type="primary" size="mini"
									icon="el-icon-sell">{{$t('i18nn_ad4345dbaabe1479')}}</el-button>
								<el-button @click="delAction($event, scope.row)" type="danger" size="mini"
									icon="el-icon-minus">{{$t("FormMsg.Delete")}}</el-button>
								<el-button @click="openEdit($event, scope.row, scope.$index)" type="warning" size="mini"
									icon="el-icon-edit">{{$t("FormMsg.Edit")}}</el-button>
							</div>

							<div style="margin-bottom: 10px;" v-if="10==scope.row.status">
								<el-button @click="openEdit($event, scope.row, scope.$index)" type="warning" size="mini"
									icon="el-icon-edit">{{$t("FormMsg.Edit")}}</el-button>
								<el-button @click="commitCancelAction($event, scope.row, scope.$index)" type="danger" size="mini"
									icon="el-icon-sold-out">{{$t('i18nn_b4e5993f97fe04ee')}}</el-button>
							</div>

							<!-- <div style="margin-bottom: 10px;" v-if="'10'!=scope.row.trOpType&&('20' == scope.row.status||'35' == scope.row.status||'30' == scope.row.status)">
								<el-button @click="toOutAction($event, scope.row)" type="primary" size="mini"
									icon="el-icon-truck">{{$t('i18nn_f904142c2f2c8106')}}</el-button>
							</div> -->

						</div>

					</template>
				</el-table-column>
			</el-table>

			<div class="tableCon_pagination">
				<hy-page-pagination :pagination="pagination" v-on:updatePageData="getPageData"></hy-page-pagination>
			</div>
		</div>

		<!-- 明细 -->
		<DialogTransportInWhDetList :openTime="TranInDetOpenTime" :id="TranInDetId" @success="initData()">
		</DialogTransportInWhDetList>

		<!-- 售后 -->
		<QAModelRecList :openTime="QAMoRecListOpenTime" :type="'30'" :relationId="QAMoRecListRelationId"
			:relationNo="QAMoRecListRelationNo" :whNo="QAMoRecListWhNo" @success="QAMoRecListSuccess"></QAModelRecList>
		<!--查看明细-->
		<!-- <el-dialog :title="$t('FormMsg.details_View')" append-to-body :close-on-click-modal="false"
			:visible.sync="dialogShowDet" width="1200px" top="0">
			<div style="">
				<TransportManageDetList :sendId="sendId"></TransportManageDetList>
			</div>
			<div slot="footer" class="dialog-footer">
				<el-button type="primary" plain @click="dialogShowDet = false">{{$t('FormMsg.Close')}}</el-button>
			</div>
		</el-dialog> -->

		<!--  附件上传 -->
		<whFileUpload :openTime="FileUploadOpenTime" :relationId="fileRelationId" @success="FileUploadSuccess">
		</whFileUpload>
	</div>
</template>
<script>
	import {
		getDicData
	} from '@/axios/common.js';

	import {
		TransportInWh_status
	} from '@/i18n/langStatus.js';
	import {
		i18nStatus
	} from '@/i18n/FormatI18n.js';
	// import TransportManageEdit from '@/components/StorageCenter/Transport/TransportManageEdit.vue';

	import DialogTransportInWhDetList from '@/components/StorageCenter/Transport/DialogTransportInWhDetList.vue';

	import whFileUpload from '@/components/StorageCenter/components/whFileUpload.vue';
	import SelAgentUser from '@/components/Common/SelAgentUser.vue';
	import whNoSelect from '@/components/StorageCenter/components/whNoSelect.vue';
	import QAModelRecList from '@/components/StorageCenter/QA/QAModelRecList.vue';

	export default {

		components: {
			// WSkuInfo,
			// TransportManageEdit,
			// TransportManageDetList,
			DialogTransportInWhDetList,
			whFileUpload,
			SelAgentUser,
			whNoSelect,
			QAModelRecList
		},
		data() {
			return {
				dialogShowDet: false,
				sendId: null,

				fileRelationId: '',
				FileUploadOpenTime: '',

				drawerFilter: false,

				//明细
				TranInDetOpenTime: '',
				TranInDetId: '',


				//售后
				QAMoRecListOpenTime: "",
				QAMoRecListRelationId: "",
				QAMoRecListRelationNo: "",
				// QAMoRecListUserId:"",
				QAMoRecListWhNo: "",

				pickerOptions: this.$PickerDate.pickerOptions_1(),


				// UserInfo: this.$store.getters.getUserInfo,
				// dialogFormVisible: false,
				// editId: "",
				// openTimeEditId: '',
				multipleSelection: [],

				loading: false,

				loading_det: false,

				loading_load: false,
				tableData: [],
				//分页数据
				pagination: this.$Utils.defaultPagination(),
				selectOption: {
					wh_car_tms_type: [],
					// wh_no:[],
					statusList: TransportInWh_status
				},
				//查询，排序方式
				filterData: {
					orderBy: 'id_', //排序字段
					sortAsc: 'desc', //desc降序，asc升序
					// "accountPeriod":"",
					// "billNo":""
					agentUser: "",
					workNo: '',
					status: '',
					packingNo: '',
					keyword: "",
					commitDateArr: [],
					whNo: '',
					wh_car_tms_type: ''
					// putWhNo: '',
					// goodsSku: '',
					// hashCode: '',
					// goodsName: '',
					// declareNameCh: ''
				}
			};
		},
		// watch:{
		// 	'$route.query': function(newVal, oldVal) {
		//     console.log('watchKey');
		//     // if (newVal) {
		//     // console.log('watch openDateTime HyUpLoadfile.vue');
		// 		if(this.$route.query.workNo){
		// 			this.filterData.workNo = this.$route.query.workNo;
		// 			this.initData();
		// 		}

		//     // }
		//   }
		// },
		//创建时
		created() {
			// this.getPageData();
			// if (this.$route.query.workNo) {
			// 	this.filterData.workNo = this.$route.query.workNo;
			// 	// this.initData();
			// }
			if (this.$route.query.state) {
				this.filterData.status = this.$route.query.state;
				// this.initData();
			}

			if (this.$route.query && this.$route.query.agentUser) {
				this.filterData.agentUser = this.$route.query.agentUser;
				this.$nextTick(() => {
					this.$refs.SelAgentUser.initData(this.filterData.agentUser);
				});
			}
			if (this.$route.query && this.$route.query.code) {
				this.filterData.keyword = this.$route.query.code;
			}
		},
		//编译挂载前
		mounted() {
			// this.getDicData();
			//数据字典
			getDicData(['wh_car_tms_type'],
				(data) => {
					this.selectOption.wh_car_tms_type = data['wh_car_tms_type'];
				});
			this.initData();
		},
		methods: {
			initData() {
				this.pagination.current_page = 1;
				// this.currentSel = {};
				this.multipleSelection = [];
				this.getPageData();
				// this.getDicData();
			},
			//打开更多筛选
			openMoreFilter() {
				this.drawerFilter = true;
			},
			//查询更多筛选
			queryMoreFilter() {
				this.initData();
				this.drawerFilter = false;
			},
			i18nFormatter(value) {
				return i18nStatus(value, this.selectOption.statusList)
			},
			changeAgentUser(val) {
				this.filterData.agentUser = val;
				this.initData();
			},
			changWhNo(data) {
				console.log('changWhNo', data);
				this.filterData.whNo = data.code;
				// this.filterData.userId = data.userId;
				this.initData();
			},

			//选择行
			handleCurrentChange(row, event, column) {
				// this.$refs.multipleTable.toggleRowSelection(row, true); //点击选中
			},
			//多选
			handleSelectionChange(val) {
				console.log(val);
				this.multipleSelection = val;
			},

			//跳转页面
			// toPageUrl(name){
			//   this.$router.push({'name':name});
			// },
			toCreate() {
				// this.$router.push({
				// 	name: "createWorkOrder",
				// 	query: {
				// 		orderType: '30'
				// 	}
				// })
				this.openDioalog();
			},
			//打开新增编辑，弹窗
			openDioalog() {
				//   // console.log(formParm);
				// this.dialogFormVisible = true;
				// this.editId = null;
				// this.openTimeEditId = new Date().getTime();

				this.$router.push({
					name: "TransportInWhEdit"
				});
			},
			// //打开编辑
			openEdit(event, row, index) {
				event.stopPropagation();
				// this.openDioalog(row);

				// this.dialogFormVisible = true;
				// this.editId = row.id;
				// this.openTimeEditId = new Date().getTime();
				// this.getPageDetData(row.id);
				this.$router.push({
					name: "TransportInWhEdit",
					query: {
						editId: row.id
					}
				})
			},

			//查看详情
			// showDetList($event, row) {
			// 	$event.stopPropagation();
			// 	// this.dialogShowDet = true;
			// 	// this.sendId = row.id;
			// 	// this.$router.push({
			// 	// 	name: "TransportInWhEdit",
			// 	// 	query: {
			// 	// 		editId: row.id
			// 	// 	}
			// 	// });
			// 	this.$router.push({
			// 		name: "TransportInWhDetList",
			// 		query: {
			// 			id: row.id
			// 		}
			// 	});

			// },

			// 详情
			showDetList(event, row) {
				event.stopPropagation();
				this.TranInDetId = row.id;
				this.TranInDetOpenTime = new Date().getTime();
			},

			//售后
			openQARecList(event, row) {
				event.stopPropagation();
				this.QAMoRecListRelationId = row.id;
				this.QAMoRecListRelationNo = row.inWhCode;
				// this.QAMoRecListUserId = row.userId;
				this.QAMoRecListWhNo = row.whNo;
				this.QAMoRecListOpenTime = new Date().getTime();
			},
			QAMoRecListSuccess() {
				this.initData();
			},

			// editSuccess() {
			// 	this.dialogFormVisible = false;
			// 	this.initData();
			// },
			//明细
			//请求分页数据
			// getPageDetData(sendId) {
			//   // let _this = this;
			//   this.loading_det = true;
			//   this.$http.put(this.$urlConfig.WhShipmentsListDetPageList, {
			//       // "sortAsc": this.filterData.sortAsc,
			//       // "orderBy": this.filterData.orderBy,
			//       "offset": 0, //当前页
			//       "limit": 100, //当前页显示数目

			//       sendId: sendId,

			//       // putWhNo: this.filterData.putWhNo ? this.filterData.putWhNo : null,
			//       // goodsSku: this.filterData.goodsSku ? this.filterData.goodsSku : null,
			//       // hashCode: this.filterData.hashCode ? this.filterData.hashCode : null,
			//       // goodsName: this.filterData.goodsName ? this.filterData.goodsName : null,
			//       // declareNameCh: this.filterData.declareNameCh ? this.filterData.declareNameCh : null,
			//     })
			//     .then(({ data }) => {

			//     // console.log(this.$store.getters.getUserInfo);
			//       console.log("分页，请求成功");
			//       console.log(data);
			//       
			//       this.loading_det = false;
			//       //表格显示数据
			//       this.form.sendRecordDtoList = data.rows;
			//       //当前数据总条数
			//       // this.pagination.total = parseInt(data.total);
			//       //当前页数
			//       // this.pagination.current_page = parseInt(data.current);
			//       //当前页条数
			//       // this.pagination.page_size = parseInt(data.size);


			//     })
			//     .catch((error) => {
			//       console.log(error);
			//       console.log("分页，请求失败");
			//       this.loading_det = false;
			//     });
			// },
			//出库
			// toOutAction(event, row) {
			// 	event.stopPropagation();

			// 	this.$router.push({name:"TransportInWhByToOut",query:{id:row.id}});
			// },
			//提交
			commitAction(event, row) {
				event.stopPropagation();
				this.$confirm(this.$t('tips.confirmSubmit'), this.$t('tips.tipsTitle'), {
						// confirmButtonText: this.$t('i18nn_204ffab8a6e01870'),
						// cancelButtonText: this.$t('i18nn_4b3340f7872b411f'),
						type: 'warning'
					})
					.then(() => {

						this.postData(this.$urlConfig.WhTransferCommit, {
							id: row.id
						});
					})
					.catch(() => {});
			},
			//删除
			delAction(event, row) {
				event.stopPropagation();
				this.$confirm(this.$t("FormMsg.confirm_Delete"), this.$t('tips.tipsTitle'), {
						// confirmButtonText: this.$t('i18nn_204ffab8a6e01870'),
						// cancelButtonText: this.$t('i18nn_4b3340f7872b411f'),
						type: 'warning'
					})
					.then(() => {
						// this.delDataAction(event, row);
						this.postData(this.$urlConfig.WhTransferDel + '/' + row.id, {}, 'delete');
					})
					.catch(() => {});
			},

			//撤销
			commitCancelAction(event, row) {
				event.stopPropagation();

				let parm = [];

				if (row) {
					//     //单条
					parm = [row.id];
				} else {
					//多条
					let dataList = this.multipleSelection;
					if (dataList.length < 1) {
						this.$message.warning(this.$t('Storage.DropShipping.sel_tips_1'));
						return;
					}
					// if (dataList.findIndex(item=> '10'!=item.status)>-1) {
					//   this.$message.warning(this.$t('tips.long_InWarehouse_1'));
					//   return;
					// }

					let dataListParm = dataList.map(v => v.id);
					parm = dataListParm;
				}

				this.$confirm(this.$t('Storage.DropShipping.sure_submit_1') + parm.length + this.$t(
						'Storage.DropShipping.sure_submit_2'), this.$t('tips.tipsTitle'), {
						// confirmButtonText: this.$t('i18nn_204ffab8a6e01870'),
						// cancelButtonText: this.$t('i18nn_4b3340f7872b411f'),
						type: 'warning'
					})
					.then(() => {
						// {"ids":[11111111111111]}
						this.postData(this.$urlConfig.WhTransferRemove, {
							"ids": parm
						});
					})
					.catch(() => {});
			},

			//删除
			// delDataAction(event, row) {
			// 	event.stopPropagation();
			// 	console.log('delDataAction', row);
			// 	this.postData(this.$urlConfig.WhTransferDel + '/' + row.id, {}, 'delete');
			// },

			//发货指令
			// shipmentsAction(event, row) {
			// 	event.stopPropagation();
			// 	this.$confirm(this.$t('Storage.invoice_list.submit_sure'), this.$t('tips.tipsTitle'), {
			// 			// confirmButtonText: this.$t('i18nn_204ffab8a6e01870'),
			// 			// cancelButtonText: this.$t('i18nn_4b3340f7872b411f'),
			// 			type: 'warning'
			// 		})
			// 		.then(() => {
			// 			this.postData(this.$urlConfig.WhShipmentsListCommand + '/' + row.id, {}, () => {
			// 				this.$message.success(this.$t('tips.submitSuccess'));
			// 			});
			// 		})
			// 		.catch(() => {
			// 		});
			// },

			//下载提货单
			// downloadBillLading(event, row) {
			// 	event.stopPropagation();
			// },

			//打开选择数据
			// openSelData(event, row, index) {
			//   event.stopPropagation();

			//   this.dialogSelVisible = true;
			//   this.openRowData = row;
			//   this.openRowIndex = index;
			//   // this.$nextTick(() => {
			//   //   this.$refs.hyUpLoadImg1.openFile();
			//   // });
			// },
			// //选择数据后回调
			// selWhGoodsData(selRow) {
			//   this.dialogSelVisible = false;
			//   console.log('selRow', selRow);
			//   let row = this.openRowData;
			//   // row.putWhNo = selRow.putWhNo;
			//   // row.plPlaceNo = selRow.placeNo;
			//   row.goodsSku = selRow.goodsSku;
			//   row.sendAcount = 0;
			//   row.mySkuDto = selRow;

			//   // row.goodsImg = selRow.goodsImg;
			//   // row.goodsName = selRow.goodsName;
			//   // // row.goodsId = selRow.id;

			//   // // row.cusCode = selRow.cusCode;
			//   // row.goodsWeight = selRow.goodsWeight;
			//   // // row.weightUnit = selRow.weightUnit;
			//   // row.goodsLength = selRow.goodsLength;
			//   // row.goodsWidth = selRow.goodsWidth;
			//   // row.goodsHeight = selRow.goodsHeight;
			//   // row.sizeUnit = selRow.sizeUnit;

			//   // row.safeStock = selRow.safeStock;

			//   // // row.defCode = selRow.defCode;
			//   // // row.hashCode = selRow.hashCode;
			//   // // row.declareNameCh = selRow.declareNameCh;
			//   // // row.declareNameEn = selRow.declareNameEn;
			//   // row.goodsNameEn = selRow.goodsNameEn;
			//   // row.declarePrice = selRow.declarePrice;

			//   // defCode: '', //this.$t('i18nn_791e36963e37617a'),
			//   // hashCode: '', //this.$t('i18nn_198738759379a3c0'),
			//   // declareNameCh: '', //this.$t('i18nn_73d237ea940222ce'),
			//   // declareNameEn: '', //this.$t('i18nn_9894786aad05f849'),
			//   // declarePrice: '', //this.$t('i18nn_fe28507421c4c3b3'),

			//   this.$set(this.form.sendRecordDtoList, this.openRowIndex, row);

			//   //计算
			//   // this.CalculateTotalWeAndVo(row,this.openRowIndex);
			//   // this.openRowData = row;
			//   // this.openRowIndex = index;
			//   // this.$nextTick(() => {
			//   //   this.$refs.hyUpLoadImg1.openFile();
			//   // });
			// },

			// hyUpLoadImg1: function(childValue) {
			//   // childValue就是子组件传过来的值
			//   this.form.goodsImg = childValue;
			// },

			//查看图片
			// openBigImg(event, imgsrc) {
			//   event.stopPropagation();
			//   console.log('openBigImg', imgsrc);
			//   this.dialogImgVisible = true;
			//   this.imgUrlBigShow = imgsrc;
			// },

			//点击状态为非出账弹出窗
			// noConfirm(){
			//   this.$alert(this.$t('i18nn_e741d17b1fd891c2')已出账this.$t('i18nn_d6f109b7bee2f33f')确认"操作，请核实！', this.$t('tips.tipsTitle'), {
			//         type: 'warning',
			//         //confirmButtonText: this.$t('i18nn_204ffab8a6e01870'),
			//       });
			// },
			//提交信息
			// submitForm(formName) {
			//   this.$refs[formName].validate(valid => {
			//     if (valid) {
			//       // let formData = Object.assign({}, this.form);
			//       let formData = JSON.parse(JSON.stringify(this.form));
			//       //置空不需要传的数据
			//       formData.sendRecordDtoList.forEach(item=>{
			//         item.whFc = null;
			//       });
			//       //浅拷贝、对象属性的合并
			//       if (0 === this.dialogFormStatus) {
			//         formData.id = null;
			//         formData.userId = this.UserInfo.id;

			//         this.postData(this.$urlConfig.WhShipmentsListUpdate, formData);
			//       } else {
			//         formData.userId = this.UserInfo.id;
			//         this.postData(this.$urlConfig.WhShipmentsListUpdate, formData);
			//       }
			//     } else {
			//       console.log('error submit!!');
			//       this.$alert(this.$t('tips.checkSubmitData'), this.$t('tips.tipsTitle'), {
			//         type: 'warning'
			//         //confirmButtonText: this.$t('i18nn_204ffab8a6e01870'),
			//       });
			//       return false;
			//     }
			//   });
			// },
			//重置输入框
			// resetForm(formName) {
			//   console.log(formName);
			//   // console.log(this.$refs[formName]);

			//   this.$nextTick(() => {
			//     if (this.$refs[formName]) {
			//       this.$refs[formName].resetFields();
			//       if ('form' === formName) {
			//         this[formName]['sendRecordDtoList'] = [];
			//       }
			//     } else {
			//       console.log('this.$refs[formName]', this.$refs[formName]);
			//     }
			//   });
			// },

			//提交信息
			postData(url, formData, type) {
				// HttpTypelet _this = this;
				this.loading = true;
				this.loading_load = true;
				let HttpType = {};
				if ('delete' == type) {
					HttpType = this.$http.delete(url, formData);
				} else {
					HttpType = this.$http.put(url, formData);
				}
				HttpType.then(({
					data
				}) => {
					console.log(this.$t('i18nn_bc868e024b80d2e3'));
					console.log(data);

					this.loading = false;
					this.loading_load = false;
					if (200 == data.code) {
						// this.dialogFormVisible = false;
						this.initData();
						if ("delete" == type) {
							this.$message({
								type: 'success',
								message: this.$t('tips.successfullyDelete')
							});
						} else {
							this.$alert(this.$t('tips.submitSuccess'), this.$t('tips.tipsTitle'), {
								type: 'success'
							});
						}
					} else {
						// if (!data.msg) {
						//   data.msg = this.$t('tips.submitError');
						// }
						this.$alert(data.msg ? data.msg : this.$t("tips.submitError"), this.$t('tips.tipsTitle'), {
							type: 'warning'
							//confirmButtonText: this.$t('i18nn_204ffab8a6e01870'),
						});
					}
				}).catch(error => {
					console.log(error);
					console.log(this.$t('tips.submitError'));
					this.loading = false;
					this.loading_load = false;
					this.$alert(this.$t('tips.submitRequestError'), this.$t('tips.tipsTitle'), {
						type: 'warning'
						//confirmButtonText: this.$t('i18nn_204ffab8a6e01870'),
					});
				});
			},

			handleClick(tab, event) {
				console.log(tab, event);
				// this.form.sendRecordDtoList = [];
			},

			//打开导入弹窗
			// openExcelDialog() {
			//   this.dialogUploadVisible = true;
			//   // this.excelData = [];
			//   this.form.sendRecordDtoList = [];
			//   // this.excelFileName = "";
			//   // this.$refs.file.value = '';
			// },
			// fileChange(e) {
			//   // this.loading = true;
			//   console.log(e);

			//   console.log(this.$t('i18nn_f156a9536049f461'), this.$refs.file);
			//   // this.excelData = [];
			//   this.form.sendRecordDtoList = [];
			//   this.fileExcel = null,
			//   this.excelFileName = "";
			//   if (!this.$refs.file || !this.$refs.file.files) {
			//     this.$alert(this.$t('module.upload.Unknown_file'), this.$t('tips.tipsTitle'), {
			//       // //confirmButtonText: this.$t('i18nn_204ffab8a6e01870'),
			//     });
			//     return;
			//   }
			//   if (this.$refs.file.files[0]) {
			//     // if (this.fileAccept.indexOf(this.$refs.file.files[0].type) > -1) {
			//     if (this.$refs.file.files[0].size < 1024 * 1024 * 10) {
			//       this.fileExcel = this.$refs.file.files[0];
			//       this.excelFileName = this.fileExcel.name;
			//       // console.log(this.$t('i18nn_f156a9536049f461'),this.$refs.file);
			//       // this.uploadExcel();
			//     } else {
			//       this.$alert(this.$t('module.upload.uploaded_over')+'10M', this.$t('tips.tipsTitle'), {});
			//     }
			//     // } else {
			//     //   this.$alert(this.$t(this.$t('i18nn_4dadf13ebce2167d')), this.$t('tips.tipsTitle'), {
			//     //   });
			//     // }
			//   } else {
			//     // this.$alert(this.$t('i18nn_c8e99e36cefd743a'), this.$t('tips.tipsTitle'), {
			//     // });
			//     console.log(this.$t('i18nn_c8e99e36cefd743a'), this.$refs.file);
			//   }
			// },
			//  openExcel() {
			// try{
			//   this.$refs.file.value = '';
			// }catch(e){
			//   console.log(e);
			//   //TODO handle the exception
			// }
			//    // this.$refs.file.value = '';
			//    // this.$refs.file.dispatchEvent(new MouseEvent('click'));
			//    var comment = this.$refs.file;
			//    if (document.all) {
			//      // For IE
			//      comment.click();
			//    } else if (document.createEvent) {
			//      // 在ff中要为a标签添加click事件，并且侦听该事件
			//      var ev = document.createEvent('MouseEvents');
			//      ev.initEvent('click', false, true);
			//      comment.dispatchEvent(ev);
			//    } else {
			//      // this.$alert('打开上传文件有误，请联系客服', this.$t('tips.tipsTitle'), {
			//      this.$alert(this.$t('module.upload.open_error_img'), this.$t('tips.tipsTitle'), {
			//        // //confirmButtonText: this.$t('i18nn_204ffab8a6e01870'),
			//      });
			//    }
			//  },
			//  //导入excel
			//  uploadExcel() {
			//    if (!this.fileExcel) {
			//      this.$alert(this.$t('module.upload.Please_sel_file'), this.$t('tips.tipsTitle'), {});
			//      return;
			//    }

			//    this.loadingExcel = true;

			//    let file = this.fileExcel;
			//    var formdata = new FormData();
			//    formdata.append('file', file);

			//    this.$http
			//      .post(this.$urlConfig.WhShipmentsListExUpload, formdata, {
			//        headers: {
			//          'Content-Type': 'multipart/form-data'
			//        }
			//      })
			//      .then(({ data }) => {
			//        console.log(this.$t('i18nn_bc868e024b80d2e3'));
			//        console.log(data);
			//        this.loadingExcel = false;
			//        if (200 == data.code && data.rows) {
			//          // this.excelData = data.rows;

			//          this.form.sendRecordDtoList = data.rows;

			//          this.$forceUpdate();
			//          // if('1'==data.data.flag){
			//          //   this.excelData = data.data.data;
			//          // } else {
			//          //   this.$alert(data.data.errorMsg, this.$t('tips.tipsTitle'), {
			//          //     type: 'warning',
			//          //     //confirmButtonText: this.$t('i18nn_204ffab8a6e01870'),
			//          //   });
			//          // }
			//        } else {
			//          this.$alert(data.msg ? data.msg : this.$t("tips.submitError"), this.$t('tips.tipsTitle'), {
			//            type: 'warning'
			//            //confirmButtonText: this.$t('i18nn_204ffab8a6e01870'),
			//          });
			//        }
			//        // if (200 == data.code && data.data) {
			//        //   if('1'==data.data.flag){
			//        //     this.excelData = data.data.data;
			//        //   } else {
			//        //     this.$alert(data.data.errorMsg, this.$t('tips.tipsTitle'), {
			//        //       type: 'warning',
			//        //       //confirmButtonText: this.$t('i18nn_204ffab8a6e01870'),
			//        //     });
			//        //   }

			//        // } else {
			//        //   this.$alert(data.msg?data.msg:this.$t("tips.submitError"), this.$t('tips.tipsTitle'), {
			//        //     type: 'warning',
			//        //     //confirmButtonText: this.$t('i18nn_204ffab8a6e01870'),
			//        //   });
			//        // }
			//      })
			//      .catch(error => {
			//        console.log(error);
			//        console.log(this.$t('tips.submitError'));
			//        this.loadingExcel = false;
			//        this.$alert(this.$t('tips.submitRequestError'), this.$t('tips.tipsTitle'), {
			//          type: 'warning'
			//          //confirmButtonText: this.$t('i18nn_204ffab8a6e01870'),
			//        });
			//      });
			//  },
			//提交-导入excel
			// submitExcelForm(){
			//   this.loadingExcel = true;
			//   this.$http.put(this.$urlConfig.WhPackingListExSubmit, this.excelData)
			//     .then(({ data }) => {
			//       console.log(this.$t('tips.submitSuccess'));
			//       console.log(data);
			//       this.loadingExcel = false;
			//       if (200 == data.code) {
			//         if(data.data){
			//           // this.excelData = data.data.data;
			//           this.dialogUploadVisible = false;
			//           this.excelData = [];
			//           this.excelFileName = "";

			//           this.initData();
			//           this.$alert(this.$t('i18nn_73dd0d43f580bc68'), this.$t('tips.tipsTitle'), {
			//             type: 'success',
			//             //confirmButtonText: this.$t('i18nn_204ffab8a6e01870'),
			//           });
			//         } else {
			//           this.$alert(data.data.errorMsg, this.$t('tips.tipsTitle'), {
			//             type: 'warning',
			//             //confirmButtonText: this.$t('i18nn_204ffab8a6e01870'),
			//           });
			//         }

			//       } else {
			//         this.$alert(data.msg?data.msg:this.$t("tips.submitError"), this.$t('tips.tipsTitle'), {
			//           type: 'warning',
			//           //confirmButtonText: this.$t('i18nn_204ffab8a6e01870'),
			//         });
			//       }
			//     })
			//     .catch((error) => {
			//       console.log(error);
			//       console.log(this.$t("tips.submitError"));
			//       this.loadingExcel = false;
			//       this.$alert(this.$t("tips.submitRequestError"), this.$t('tips.tipsTitle'), {
			//         type: 'warning',
			//         //confirmButtonText: this.$t('i18nn_204ffab8a6e01870'),
			//       });
			//     });
			// },

			//查询数据
			// serPageData() {
			//   this.pagination.current_page = 1;
			//   this.getPageData();
			// },
			handleSelectStatus(key, keyPath) {
				// console.log(key, keyPath);
				this.filterData.status = key;
				this.initData();
			},
			//分页的筛选项数据
			pageFilterData() {

				let commitDateArr = this.filterData.commitDateArr;
				let startCommitDate = '';
				let endCommitDate = '';
				if (commitDateArr && commitDateArr.length == 2) {
					startCommitDate = commitDateArr[0];
					endCommitDate = commitDateArr[1];
				}

				// startCommitDate  endCommitDate

				return {
					workNo: this.filterData.workNo ? this.filterData.workNo : null,
					proxyUserId: this.filterData.agentUser ? this.filterData.agentUser : null,
					status: this.filterData.status ? this.filterData.status : null,
					packingNo: this.filterData.packingNo ? this.filterData.packingNo : null,
					keyword: this.filterData.keyword ? this.filterData.keyword : null,
					// // putWhNo: this.filterData.putWhNo ? this.filterData.putWhNo : null,
					whNo: this.filterData.whNo ? this.filterData.whNo : null,

					startCommitDate: startCommitDate ? startCommitDate : null,
					endCommitDate: endCommitDate ? endCommitDate : null,

					carTmsType: this.filterData.wh_car_tms_type ? this.filterData.wh_car_tms_type : null,
					// hashCode: this.filterData.hashCode ? this.filterData.hashCode : null,
					// goodsName: this.filterData.goodsName ? this.filterData.goodsName : null,
					// declareNameCh: this.filterData.declareNameCh ? this.filterData.declareNameCh : null,
				};
			},
			//请求分页数据
			getPageData() {

				let filterData = Object.assign({
						offset: (this.pagination.current_page - 1) * this.pagination.page_size, //当前页
						limit: this.pagination.page_size //当前页显示数目
					},
					this.pageFilterData()
				);

				// let _this = this;
				this.loading_load = true;
				this.$http
					.put(this.$urlConfig.WhTransferPageList, filterData)
					.then(({
						data
					}) => {
						// console.log(this.$store.getters.getUserInfo);
						console.log('分页，请求成功');
						console.log(data);

						this.loading_load = false;
						//表格显示数据
						this.tableData = data.rows;
						//当前数据总条数
						this.pagination.total = parseInt(data.total);
						//当前页数
						// this.pagination.current_page = parseInt(data.current);
						//当前页条数
						// this.pagination.page_size = parseInt(data.size);
					})
					.catch(error => {
						console.log(error);
						console.log('分页，请求失败');
						this.loading_load = false;
					});
			},

			//新增
			// addLe2() {
			//   console.log('this.form', this.form);
			//   this.form.sendRecordDtoList.push({
			//     goodsSku: '', //this.$t('i18nn_31f887c1ada79a99'),
			//     sendAcount: null, //this.$t('i18nn_b9d4d3f6183bf3c1'),
			//     toWhNo: '', //this.$t('i18nn_0f859b9b45732564'),
			//     fbaNo:'',//fba编号
			//     mySkuDto: {
			//       // cusCode: '', //this.$t('i18nn_64fed2851f79d875'),
			//       goodsName: '', //this.$t('i18nn_b23004db5349dfd2'),
			//       goodsNameEn: '', //this.$t('i18nn_05141ac0139eaf01'),
			//       goodsImg: '', //this.$t('i18nn_877c5a0e44a0eb07'),
			//       // defCode: '', //this.$t('i18nn_791e36963e37617a'),
			//       // hashCode: '', //this.$t('i18nn_198738759379a3c0'),
			//       // declareNameCh: '', //this.$t('i18nn_73d237ea940222ce'),
			//       // declareNameEn: '', //this.$t('i18nn_9894786aad05f849'),
			//       // declarePrice: '', //this.$t('i18nn_fe28507421c4c3b3'),
			//       goodsWeight: '', //this.$t('i18nn_96f1a4b17e75892d'),
			//       sizeUnit: '', //this.$t('i18nn_38bc71c0dc55904b'),
			//       // weightUnit: '', //this.$t('i18nn_8e01866868930d09'),
			//       goodsLength: '', //this.$t('i18nn_2cba96917484569a'),
			//       goodsWidth: '', //this.$t('i18nn_9e24e0e4745f4948'),
			//       goodsHeight: '', //this.$t('i18nn_c5fa0857bc0df2d6'),
			//       safeStock: '', //this.$t('i18nn_004617dc47191cb8'),
			//       // sendAcount: null //this.$t('i18nn_b9d4d3f6183bf3c1'),
			//       // "id":"",//"数据id"
			//       remark: ''
			//     }
			//   });
			//   this.$forceUpdate();
			// },
			// //删除
			// delLe2(index) {
			//   this.form.sendRecordDtoList.splice(index, 1);
			// },


			//打开附件上传
			openUploadFile(event, row) {
				event.stopPropagation();
				// this.isShowFileUpload = true;
				this.fileRelationId = row.id;
				this.FileUploadOpenTime = new Date().getTime();
			},
			//附件上传成功回调
			FileUploadSuccess(data) {
				this.initData();
				// event.stopPropagation();
				// this.isShowFileUpload = true;
				// this.fileRelationId = row.id;
				// this.FileUploadOpenTime = new Date().getTime();
			},
			//操作
			// sureBillAction(row,msg,state) {
			//   console.log(this.$t('i18nn_4f5bb4ff8b3d804b'), row);
			//   console.log(row);
			//   this.$confirm(this.$t('i18nn_e8ec92802315a287')+msg+'通过该笔账单?', this.$t('tips.tipsTitle'), {
			//     //confirmButtonText: this.$t('i18nn_204ffab8a6e01870'),
			//     //cancelButtonText: this.$t('i18nn_4b3340f7872b411f'),
			//     type: 'warning'
			//   }).then(() => {
			//     //确认
			//     let dataParm = {
			//       ids:[row.id],
			//       state:state,
			//     }
			//     this.updatePageData(dataParm, msg);
			//   }).catch(() => {

			//   });

			// },
			//更新特定数据,
			// updatePageData(dataParm, msg) {
			//   // let _this = this;
			//   this.loading_load = true;
			//   //浅拷贝、对象属性的合并
			//   let dataParmAss = Object.assign({}, dataParm);
			//   // dataParmAss.isDefault = 1;
			//   this.$http.post(this.$urlConfig.PartnerMatchingFeeModifyPage, dataParmAss)
			//     .then(({ data }) => {
			//       console.log(msg + "特定数据，请求成功");
			//       console.log(data);
			//       if (200 == data.code) {
			//         this.$message.success(msg + '成功！');
			//         this.getPageData();
			//       } else {
			//         this.$message.warning(data.msg ? data.msg : msg + '失败,请重试');
			//       }
			//     })
			//     .catch((error) => {
			//       console.log(error);
			//       console.log("更新特定数据，请求失败");
			//       this.loading_load = false;
			//       this.$message.warning('' + msg + '失败,请重试！');
			//     });
			// },
			//查询数据字典
			// getDicData() {
			// 	// let _this = this;
			// 	// console.log(keyword);

			// 	// this.loading_load = true;
			// 	this.$http
			// 		.put(this.$urlConfig.HyDicQueryList, ['wh_car_tms_type'])
			// 		.then(({
			// 			data
			// 		}) => {
			// 			console.log('查询数据字典，请求成功');
			// 			console.log(data);
			// 			if (200 == data.code && data.data) {
			// 				// this.selectOption.wh_weight_unit = data.data['wh_weight_unit'];
			// 				// this.selectOption.wh_vol_unit = data.data['wh_vol_unit'];
			// 				// this.selectOption.wh_goods_fee_type = data.data['wh_goods_fee_type'];
			// 				// this.selectOption.wh_size_unit = data.data['wh_size_unit'];
			// 				this.selectOption.wh_car_tms_type = data.data['wh_car_tms_type'];
			// 				// this.selectOption.wh_no = data.data['wh_no'];
			// 			} else {
			// 				if (!data.msg) {
			// 					data.msg = this.$t("tips.submitError");
			// 				}
			// 				this.$message.warning(data.msg);
			// 			}
			// 		})
			// 		.catch(error => {
			// 			console.log(error);
			// 			console.log('查询数据字典接口，请求失败');
			// 			this.$message.error(this.$t("tips.submitRequestError"));
			// 		});
			// }
		}
	};
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">
	// /deep/ .el-input-number,
	// .el-select {

	// 	// width: 100px;
	// 	.el-input__inner {
	// 		text-align: left;
	// 	}
	// }

	// .form_msg {
	// 	color: #e6a23c;
	// }
</style>